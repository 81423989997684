.msg-assistant {
  p {
    margin-top: 10px;
    margin-bottom: 10px;

    &:first-child,
    &:last-child {
      margin-bottom: 0;
    }
  }
}
